import dayjs from "dayjs";

import "dayjs/locale/en-gb";
import "dayjs/locale/nl";

// dayjs.extend(isoWeek);
// dayjs.extend(isToday);
// dayjs.extend(duration);
// dayjs.extend(localeData);
// dayjs.extend(localizedFormat);
// dayjs.extend(relativeTime);

export default {
    install: (app, { locale }) => {
        setLocale(locale);
        app.provide("setDayjsLocale", (locale) => setLocale(locale));
        app.provide("dayjs", dayjs);
    },
};

function setLocale(locale) {
    // To prevent the selection of American english (week starts on Sunday)
    if (locale === "en") {
        dayjs.locale("en-gb");
        return;
    }

    dayjs.locale(locale);
}
